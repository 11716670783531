window.Client = {
	init: function() {
		// Do something on initialization
	},

	onRender: function(screenId) {
		// Do something when the flow renders
	}
}
window.addEventListener('heyflow-init', (e) => {
    const query   = window.location.hash;
    const params  = new URLSearchParams(query);

    const request = {
        subid:   params.get('subid')   || '',
        amount:  params.get('amount')  || '',
        offerid: params.get('offerid') || '',
        tid:     params.get('tid')     || '',
        s1:      params.get('s1')      || '',
        s2:      params.get('s2')      || '',
        s3:      params.get('s3')      || '',
        s4:      params.get('s4')      || '',
        s5:      params.get('s5')      || '',
    };

    window.localStorage.setItem('_thrive', JSON.stringify(request));
    console.log("thriveloaded");
});

window.addEventListener('heyflow-submit', (e) => {
    const json    = window.localStorage.getItem('_thrive');
    const request = JSON.parse(json);
    const get     = new URLSearchParams(request).toString();
    const url     = `https://claimstrk.com/path/postback.php?ptype=js&${get}`;
    const http    = new XMLHttpRequest();
    http.open('GET', url);
    http.send(null);
    http.onreadystatechange = (e) => {
        if (e.readyState == 4 && e.status == 200) {
            console.log('Thrive OK');
        }
    }
});